.main {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
}

.introBox {
    background-color: rgba(255, 255, 255, 0.299);
    color: white;
    display: flex;
    text-align: center;
    border-radius: 20px;
    max-width: 500px;
    margin: 0 auto;
    padding: 50px 70px 50px 70px;
    font-size: 3em;
}